/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-24 11:30:39
 * @Module: 菜单列表
 */
 <template>
  <div>

    <ul class="menuLsit-box"
        :style="screenWidth>1300?'right:244px;':screenWidth>980?'right:78px;':'display:none;'">
      <li v-for="item in menuList"
          :key="item.key"
          class="hover menuLsit-item"
          slot="reference"
          @mouseenter="hoverKey=item.key"
          @mouseleave="hoverKey = ''"
          @click="menuClick(item)">
        <el-popover placement="left"
                    trigger="hover"
                    v-if="item.key =='share'">
          <div slot="reference">
            <span class="iconfont"
                  :class="item.font"
                  v-show="!hoverKey||hoverKey!==item.key"></span>
            <span v-show="hoverKey==item.key">{{item.title}}</span>
          </div>
          <qrcode-vue :value="qrcode"
                      :size="100"
                      style="width:100px;"
                      level="H"></qrcode-vue>
        </el-popover>
        <div v-else-if="item.key =='collect'">
          <span class="iconfont"
                :class="item.font"
                :style="data.has_collect&&'color:#df2a29'"
                v-show="!hoverKey||hoverKey!==item.key"></span>
          <span v-show="hoverKey==item.key">{{item.title}}</span>
        </div>
        <div v-else>
          <span class="iconfont"
                :class="item.font"
                v-show="!hoverKey||hoverKey!==item.key"></span>
          <span v-show="hoverKey==item.key">{{item.title}}</span>
        </div>
      </li>
    </ul>
    <transpond-dialog :visible="transpondDialogVisible"
                      :data="data"
                      @close="transpond_dialog_change" />
  </div>
</template>
 <script>
import QrcodeVue from 'qrcode.vue'
import transpondDialog from "@/views/homepage/module/transpondDialog"
export default {
  components: {
    QrcodeVue,
    transpondDialog
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      screenWidth: document.body.clientWidth,
      hoverKey: "",
      menuList: [
        {
          title: "回顶部",
          key: 'top',
          font: "el-icon-arrow-up"
        },
        {
          title: "收藏",
          key: 'collect',
          font: "iconshoucang1"
        },
        {
          title: "转发",
          key: 'transpond',
          font: "iconzhuanfa"
        },
        {
          title: "分享",
          key: 'share',
          font: "iconweixin1"
        },
        {
          title: "评论",
          key: 'comment',
          font: "iconpinglun1"
        }
      ],
      transpondDialogVisible: false,
      selectData: null,
    };
  },
  mounted () {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  methods: {
    menuClick ({ key }) {
      console.log(key)
      if (key == "top") {
        this.scrollTop()
      } else if (key == "comment") {
        this.scrollComment()
      } else if (key == "collect") {
        this._get_collect()
      } else if (key == "transpond") {
        this.transpondClick()
      }
    },
    // 滚到顶部
    scrollTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      // document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    // 滚到评论
    scrollComment () {
      document.querySelector("#comment-input").scrollIntoView({ block: "center", behavior: "smooth" });
    },
    // 请求收藏
    async _get_collect () {
      const { uuid, has_collect } = this.data
      const { status, info } = await this.$api.momentCollectApi({ model_id: uuid, type: has_collect ? 0 : 1 })
      if (status == 200) {
        this.$message.success(info)
        setTimeout(() => this.$router.go(0), 1000)
      } else {
        this.$message.error(info)
      }
    },
    //转发点击
    transpondClick () {
      this.transpondDialogVisible = true
    },
    // 转发弹出框回调
    transpond_dialog_change (res) {
      this.transpondDialogVisible = false
      if (res) {
        console.log("成功")
        this.$router.go(0)
      } else {
        console.log("取消")
      }
    },
  },
  watch: {
    screenWidth (val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val
        this.timer = true
        let that = this
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.screenWidth)
          that.timer = false
        }, 400)
      }
    }
  },
  computed: {
    qrcode () {
      const { uuid, type } = this.data
      const url = "http://mobile.toutiao.mcn-open.com/pages/anew/shouye/"
      if (type == 0) {   //动态
        return url + `shipindetail/shipindetail?uuid=${uuid}&type=${type}`
      } else if (type == 1) {  //文章
        return url + `wenzhangdetail/wenzhangdetail?uuid=${uuid}&type=${type}`
      } else if (type == 2) {  //长视频
        return url + `shipindetail/shipindetail?uuid=${uuid}&type=${type}`
      } else {
        return url
      }
    }
  }, //计算属性
};
 </script>
 <style lang='scss' scoped>
.menuLsit-box {
  position: fixed;
  // right: 244px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  height: 346px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 1s;
}
.menuLsit-item {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 12px;
  color: #ccc;
}
.color {
  color: #df2a29;
}
</style>