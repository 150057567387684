/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-20 18:18:03
 * @Module:专栏 
 */
 <template>
  <div class="column"
       v-if="columnList.length">
    <div class="column-title"><span>//</span><span>专栏</span></div>
    <div class="column-content"
         :class="{transpondBox:data.type===1}">
      <div class="column-content-leftBtn hover"
           @click="previousClick">
        <img src="@/static/starImg/lslide.png"
             style="width:15px" />
      </div>
      <div class="column-content-box">
        <!-- 视频专栏 -->
        <div class="column-content-box-panel"
             v-if="data.type===2"
             :style="'margin-left:-'+cut*202+'px'">
          <div class="column-content-box-item hover"
               v-for="(item,index) in columnList"
               :key="item.uuid"
               @click="toDetail({type:item.type,uuid:item.uuid})">
            <div class="column-content-box-item-t">
              <el-image style="width: 182px;height:102px;"
                        :src="item.cover"
                        fit="cover"></el-image>
              <div class="column-content-box-item-t-index">{{index+1}}</div>
              <div class="column-content-box-item-t-play"
                   v-if="data.uuid==item.uuid">
                <img src="@/static/homepage/video_playing.gif"
                     style="width:10px;">
                播放中
              </div>
            </div>
            <div class="column-content-box-item-b ellipsis2"
                 :class="{'column-content-box-item-b-ac':data.uuid==item.uuid}">{{item.title}}</div>
          </div>
        </div>
        <!-- 视频专栏 -end -->
        <!-- 文章专栏 -->
        <div class="column-content-box-panel"
             v-if="data.type===1"
             :style="'margin-left:-'+cut*202+'px'">
          <div class="column-content-box-item hover transpondItem"
               v-for="(item,index) in columnList"
               :key="item.uuid"
               @click="toDetail({type:item.type,uuid:item.uuid})">
            <div class="transpondItem-index">{{index+1}}</div>
            <div :class="{'column-content-box-item-b-ac':data.uuid==item.uuid}"
                 class="transpondItem-title">{{item.title}}</div>
          </div>
        </div>
        <!-- 文章专栏 -end -->
      </div>
      <div class="column-content-rightBtn hover"
           @click="nextClick">
        <img src="@/static/starImg/rslide.png"
             style="width:15px" />
      </div>
    </div>
  </div>
</template>
 <script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data () {
    return {
      cut: 0
    };
  },
  mounted () {
    let cut = 0
    this.columnList.forEach((item, index) => {
      if (item.uuid == this.data.uuid) {
        cut = index
      }
    })
    if (cut > this.columnList.length - 3) {
      this.cut = this.columnList.length - 3
    } else {
      this.cut = cut
    }
  },
  methods: {
    // 上一个
    previousClick () {
      if (this.cut <= 0) {
        this.cut = this.columnList.length - 3
      } else {
        this.cut -= 1
      }
    },
    //下一个
    nextClick () {
      if (this.cut >= this.columnList.length - 3) {
        this.cut = 0
      } else {
        this.cut += 1
      }
    },
    toDetail (res) {
      this.$store.dispatch("homepage/toDetail", res)
    }
  },
  computed: {
    columnList () {
      return this.data.moment_content.column_list || []
    }
  }
};
 </script>
 <style lang='scss' scoped>
.column {
  margin-top: 40px;
  &-title {
    font-size: 18px;
    :first-child {
      color: #df2a29;
    }
  }
  &-content {
    margin-top: 16px;
    height: 162px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-box {
      width: 586px;
      height: 100%;
      overflow: hidden;
      &-panel {
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
        transition: all 1s;
      }
      &-item {
        width: 182px;
        height: 100%;
        margin-right: 20px;
        flex-shrink: 0;
        border-radius: 4px;
        overflow: hidden;
        background: #f7f7f7;

        &-t {
          position: relative;
          background: #66f;
          &-index {
            width: 20px;
            line-height: 20px;
            text-align: center;
            background: rgba(0, 0, 0, 0.2);
            color: #fff;
            border-radius: 3px;
            position: absolute;
            top: 7px;
            left: 7px;
            font-size: 14px;
          }
          &-play {
            width: 61px;
            line-height: 22px;
            height: 22px;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 11px;
            color: #fff;
            text-align: center;
            font-size: 10px;
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            margin: auto;
          }
        }
        &-b {
          background: #f7f7f7;
          height: 60px;
          font-size: 14px;
          padding: 8px;
          line-height: 20px;
          &-ac {
            color: #df2a29;
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
.transpondBox {
  height: 102px;
}
.transpondItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &-index {
    width: 20px;
    line-height: 20px;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    border-radius: 3px;
    position: absolute;
    top: 7px;
    left: 7px;
    font-size: 14px;
  }
  &-title {
    width: 154px;
    // height: 60px;
    // position: absolute;
    // top: 0px;
    // bottom: 0px;
    // left: 0px;
    // right: 0px;
    // margin: auto;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; // 控制多行的行数
    -webkit-box-orient: vertical;
  }
}
</style>