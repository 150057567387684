/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 15:38:40
 * @Module: 详情公用
 */
 <script>
import detailUserinfo from "./module/detailuserinfo"
import tipOff from "./module/tipOff"
import comment from "./module/comment"
import recommend from "./module/recommend"
import column from "./module/column"
import menuList from "./module/menuList"
import { Loading } from 'element-ui';
export default {
  components: {
    detailUserinfo,
    tipOff,
    comment,
    recommend,
    column,
    menuList
  },
  data () {
    return {
      detailData: null
    };
  },
  mounted () {
    this._onload({ uuid: this.$route.query.uuid })
  },
  methods: {
    async _onload ({ uuid }) {
      let loading = Loading.service({ fullscreen: true });
      await this._get_detail(uuid)
      loading.close();
    },
    async _get_detail (uuid) {
      const { status, info, data } = await this.$api.momentDetailApi({ uuid })
      if (status == 200) {
        this.detailData = data
      } else {
        this.$message.error(info)
      }
    },
    async _get_like () {
      const { uuid, has_liked } = this.detailData
      const { status, info } = await this.$api.momentLikeApi({ model_id: uuid, type: has_liked })
      if (status == 200) {
        this.$message.success(info)
      } else {
        this.$message.error(info)
        this.onLike(true)
      }
    },
    onLike (back) {
      const { has_liked, likes_count } = this.detailData
      if (has_liked) {
        this.detailData.likes_count = likes_count - 1
        this.detailData.has_liked = 0
      } else {
        this.detailData.likes_count = likes_count + 1
        this.detailData.has_liked = 1
      }
      if (back) return;
      this._get_like()
    },
    onTipOff () {

    }
  },
  watch: {
    '$route' () {
      this.$router.go(0);
    }
  },
};
 </script>