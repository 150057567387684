/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 16:46:53
 * @Module: 详情用户信息
 */
 <template>
  <div class="detailuserinfo-box" @click="goInfo(data.user_id)">
    <div style="position:relative;">
      <el-avatar :size="50" :src="data.user_avatar"></el-avatar>
       <img
      src="@/static/img/renzheng.gif"
      alt=""
      v-if="!(data.user_verify == null)"
      style="width:12px;height:12px;position:absolute;bottom:1px;right:4px;"
    />
    </div>
    <div class="detailuserinfo-r">
      <div class="detailuserinfo-r-t">
        <span :class="data.is_vip ? 'new-color' : ''">{{ data.user_name }}</span>
        <img
            src="@/static/img/vip.gif"
            alt=""
            v-if="data.is_vip"
            style="margin-left:5px;width:12px;height:12px;"
          />
          <img
            src="@/static/img/broker.png"
            alt=""
            v-if="data.is_super_broker"
            style="margin-left:5px;width:12px;height:12px;"
          />
        </div>
      <div class="detailuserinfo-r-b">{{ data.created_at }}</div>
    </div>
  </div>
</template>
 <script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {
    console.log(this.data)
  },
  methods: {
     goInfo(id){
      this.$router.push({
            path: "/homepage/user/personal",
            query: {
              uuid: id
            }
          });
    },
  }
};
</script>
 <style lang='scss' scoped>
.detailuserinfo-box {
  margin: 14px 0px;
  height: 50px;
  display: flex;
  align-items: center;
  .detailuserinfo-r {
    margin-left: 10px;
    &-t {
      font-size: 14px;
      color: #333;
    }
    &-b {
      margin-top: 4px;
      color: #999;
      font-size: 12px;
    }
  }
}
.new-color {
  color: #df2a29;
}
</style>