/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-11 13:52:27
 * @Module: 推荐列表 
 */
 <template>
  <div class="recommend-box"
       v-loading="loading">
    <div class="recommend-title">
      <div>//</div>
      <div>更多推荐</div>
    </div>
    <div class="recommend-list">
      <div class="recommend-list-item hover"
           v-for="item in list"
           :key="item.uuid"
           @click="toDetail({type:item.type,uuid:item.uuid})">
        <el-image class="recommend-list-item-l"
                  :src="item.cover"
                  fit="cover"></el-image>
        <div class="recommend-list-item-r">
          <div class="recommend-list-item-r-t ellipsis2">{{item.title}}</div>
          <div class="recommend-list-item-r-b">{{item.created_at}}·{{item.type==2?"播放":"阅读"}} {{item.watch_count}}</div>
        </div>
        <div class="recommend-list-item-videoIcon"
             v-if="item.type==2">
          <i class="el-icon-caret-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data () {
    return {
      loading: false,
      list: []
    };
  },
  mounted () {
    this._get_recommendList()
  },
  methods: {
    // 获取推荐列表
    async _get_recommendList () {
      this.loading = true
      const { uuid: moment_id } = this.data
      const { status, info, data } = await this.$api.recommendListApi({ moment_id })
      this.loading = false
      if (status == 200) {
        this.list = data
        // this.$set(this.childData, data.comment_id, data.children)
      } else {
        this.$message.error(info)
      }
    },
    toDetail (res) {
      this.$store.dispatch("homepage/toDetail", res)
    }
  },
};
 </script>
 <style lang='scss' scoped>
.recommend-box {
  min-height: 300px;
  .recommend-title {
    padding-top: 20px;
    font-size: 18px;
    display: flex;
    padding-bottom: 4px;
    div:first-child {
      color: #ec7f7f;
      font-weight: 600;
    }
    div:last-child {
      color: #333;
    }
  }
}
.recommend-list {
  &-item:last-child {
    border: none;
  }
  &-item {
    padding: 12px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #eee;
    position: relative;
    &-videoIcon {
      width: 34px;
      height: 34px;
      border-radius: 34px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: auto;
      left: 62px;
      text-align: center;
      line-height: 34px;
      color: #fff;
      font-size: 24px;
    }
    &-l {
      width: 158px;
      height: 102px;
      border-radius: 4px;
    }
    &-r {
      width: 485px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 20px;
      color: #333333;
      // background: #66f;
      height: 102px;
      &-t {
        line-height: 28px;
      }
      &-b {
        color: #999999;
        font-size: 14px;
      }
    }
  }
}
</style>