/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-10 14:48:17
 * @Module: 举报
 */
 <template>
  <div class="tipOff-box">
    <div class="tipOff-t">
      <div class="tipOff-t-l">{{data.visit_count}}看过</div>
      <div class="tipOff-t-r"
           @click="onTipOff">举报</div>
    </div>
    <div class="tipOff-b">
      <div class="tipOff-b-t hover"
           @click="onLike">
        <div class="iconfont icondianzanhou"
             :class="{'is-like':data.has_liked}"></div>
        <div>{{data.likes_count}}</div>
      </div>
      <div class="tipOff-b-b">点赞，鼓励一下吧</div>
    </div>
  </div>
</template>
 <script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {
    onLike () {
      this.$emit("onLike")
    },
    onTipOff () {
      this.$emit("onTipOff")
    }
  },
};
 </script>
 <style lang='scss' scoped>
.tipOff-box {
  margin-top: 20px;
}
.tipOff-t {
  color: #999;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tipOff-b {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-t {
    width: 70px;
    height: 70px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 70px;
    color: #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div:first-child {
      font-size: 24px;
    }
    div:last-child {
      margin-top: 4px;
      font-size: 14px;
    }
  }
  &-b {
    margin-top: 10px;
    color: #333;
    font-size: 13px;
  }
}
.is-like {
  color: #df2a29;
}
</style>